import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Avatar from "avataaars";

import './../../css/devView.css';
import ConfirmPurchase from './partials/ConfirmPurchase';
import { TimeIcon, MoneyIcon } from 'images';

import mapDispatchToProps from '../../redux/dispatchers';
import * as RealtimeConnection from '../../utils/RealtimeConnection';

import URLs from '../../utils/urls';
import Translate from '../../utils/Translate'

const DevView = (props) => {
	let time = props.session.time
	let money = props.session.amount

	const [selectedPlayer, setSelectedPlayer] = React.useState({});
	const [categoryList, setCategories] = React.useState([]);
	const [selectedCategory, setSelectedCategory] = React.useState({});
	const [coursesList, setCoursesList] = useState([])

	const [selectedCoursesIds, setSelectedCoursesIds] = useState([])

	//const selectedCoursesIds = (props.session.selectedCourses[props.session.roundNumber] && props.session.selectedCourses[props.session.roundNumber][selectedPlayer.id]) ? props.session.selectedCourses[props.session.roundNumber][selectedPlayer.id].map( course => course.id) : [];

	useEffect(() => {
		if (selectedPlayer) {
			const selectedCourses = (props.session.selectedCourses[props.session.roundNumber] && props.session.selectedCourses[props.session.roundNumber][selectedPlayer.id]) ? props.session.selectedCourses[props.session.roundNumber][selectedPlayer.id].map(course => course.id) : [];
			const activeCourses = (props.session.activeSelectedCourses[selectedPlayer.id]) ? props.session.activeSelectedCourses[selectedPlayer.id].map(course => course.id) : [];

			setSelectedCoursesIds([
				...selectedCourses,
				...activeCourses
			])
		} else {
			setSelectedCoursesIds([])
		}
	}, [props.session.roundNumber, props.session.selectedCourses, props.session.activeSelectedCourses, selectedPlayer])

	useEffect(() => {
		if (selectedPlayer.id && selectedCategory.id) {
			const tmpCourses = selectedCategory.Courses.map(course => {
				let impactValues = {
					money: course.money,
					motivation: course.motivation,
					retentionRisk: course.retentionRisk,
					skills: course.skills,
					time: course.time
				}

				if (course.SimtalentCourseImpacts && course.SimtalentCourseImpacts.length > 0) {
					const characterImpact = course.SimtalentCourseImpacts.filter(impact => impact.Character.key === selectedPlayer.id)
					if (characterImpact.length > 0) {
						impactValues = characterImpact[0]
					}
				}

				const newCourse = JSON.parse(JSON.stringify(course))
				newCourse.SimtalentCourseImpacts = impactValues

				return newCourse
			})

			setCoursesList(tmpCourses)
		}
	}, [selectedCategory, selectedPlayer])

	let disabledCourses = [];
	const roundNumberKeys = Object.keys(props.session.selectedCourses)
	if (roundNumberKeys.length > 0) {
		roundNumberKeys.forEach(round => {
			if (props.session.selectedCourses[round][selectedPlayer.id]) {
				if (parseInt(round) !== props.session.roundNumber)
					disabledCourses = props.session.selectedCourses[round][selectedPlayer.id].map(course => course.id)
			}
		})
	}


	const fetchCourses = async () => {
		try {
			const response = await axios.post(URLs.coursesLink, {
				sessionId: window.sessionId,
				languageId: (localStorage.langId) ? localStorage.langId : 1
			});

			setCategories(response.data.courses);
		} catch (error) {
			console.log(error);
		}
	};

	React.useEffect(() => {
		fetchCourses();
	}, []);

	const capitalizeFirst = (text) => text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

	const selectCourse = (course) => {
		//&& props.session.eventCardAppearanceStatus.allowDevelopmentSelection
		if (selectedPlayer.id) {
			if (selectedCoursesIds.includes(course.id)) {
				//props.deSelectCourse(selectedPlayer.id, course)
				RealtimeConnection.deselectCharacterCourse(selectedPlayer.id, course)
			} else {
				if (time >= course.SimtalentCourseImpacts.time && money >= course.SimtalentCourseImpacts.money) {
					//props.selectCourse(selectedPlayer.id, course)
					RealtimeConnection.selectCharacterCourse(selectedPlayer.id, course)
				}
			}
		}
	}

	//calculate time & money
	if (props.session.activeSelectedCourses) {
		let playersIds = Object.keys(props.session.activeSelectedCourses)
		// && props.session.eventCardAppearanceStatus.allowDevelopmentSelection
		if (playersIds.length > 0) {
			playersIds.forEach(playerId => {
				props.session.activeSelectedCourses[playerId].forEach(course => {
					time -= course.SimtalentCourseImpacts.time;
					money -= course.SimtalentCourseImpacts.money;
				})
			})
		}
	}

	return (
		<>
			<div className="development-header">{<Translate alias="Board" word="Development" />}</div>
			<div className="info-container">
				<div className="people-container">
					<div className="title">{<Translate alias="Board" word="Select Person" />}</div>
					<div className="person">
						{props.players.map((player) => {
							if (player.id === "player_3" && !props.whiteCharacterStatus) {
								return null
							}
							const selected = player.id === selectedPlayer.id ? 'selected' : '';
							//const isJSON = (player.image !== null) ? JSON.parse(player.image) : false
							let isJSON = false
							let imageURL = player.image
							if (player.image) {
								if (player.image.indexOf("/uploads/characters/images") === -1) {
									isJSON = JSON.parse(player.image)
								} else {
									imageURL = URLs.backendURL + player.image
								}
							}

							return (
								<div
									onClick={() => setSelectedPlayer(player)}
									key={player.id}
									className={`player-grid ${selected}`}
								>
									{
										(isJSON) ?
											<div className={`avatar-wrapper ${player.id}`}>
												<Avatar
													style={{ width: "50px", height: "50px" }}
													//avatarStyle='Circle'
													avatarStyle='Transparent'
													topType={(isJSON.Top) ? isJSON.Top : ""}
													hairColor={(isJSON.HairColor) ? isJSON.HairColor : "Black"}
													accessoriesType={(isJSON.Accessories) ? isJSON.Accessories : ""}
													hatColor={(isJSON.HatColor) ? isJSON.HatColor : ""}
													facialHairType={(isJSON.FacialHair) ? isJSON.FacialHair : ""}
													facialHairColor={(isJSON.FacialHairColor) ? isJSON.FacialHairColor : ""}
													clotheType={(isJSON.Clothes) ? isJSON.Clothes : ""}
													clotheColor={(isJSON.ColorFabric) ? isJSON.ColorFabric : ""}
													eyeType={(isJSON.Eyes) ? isJSON.Eyes : ""}
													eyebrowType={(isJSON.Eyebrow) ? isJSON.Eyebrow : ""}
													mouthType={(isJSON.Mouth) ? isJSON.Mouth : ""}
													skinColor={(isJSON.Skin) ? isJSON.Skin : ""}
												/>
											</div>
											:
											<img style={{ width: "50px", height: "50px" }} src={imageURL} alt="" />
									}
									<div className="player-info" style={{ display: "flex", alignItems: "center" }}>
										<div>{player.name}</div>
										{ /*<div className="player-title">
											{capitalizeFirst(player.characterTitle)}
										</div>*/ }
									</div>
								</div>
							);
						})}
					</div>
				</div>
				<div className="courses-section">
					<div className="categories-list">
						<div className="total-spent">
							<h2 style={{ lineBreak: "anywhere" }}>{<Translate alias="Board" word="Course List" />}</h2>
							<div className="spent-stats">
								<div className='time-wrapper'>
									<TimeIcon
										color="white"
										width={20}
										height={22}
									/>
									<p>{time}</p>
								</div>
								<div className='money-wrapper'>
									<MoneyIcon
										color="white"
										bgColor="transparent"
										width={20}
										height={22}
									/>
									<p>{money}</p>
								</div>
							</div>
						</div>

						<h4>{<Translate alias="Board" word="Categories" />}: </h4>
						{(selectedPlayer.id && categoryList.length > 0) &&
							categoryList.map((category) => {
								let selected = category.id === selectedCategory.id ? 'selected' : '';
								return (
									<div
										onClick={() => setSelectedCategory(category)}
										key={category.id}
										className={`${selected} category`}
									>
										<div>
											{category.name}
										</div>
										<hr />
									</div>
								);
							})}
					</div>

					<div className="course-list">
						<div className="main-title">{<Translate alias="Board" word="Available Courses" />}: </div>
						{(selectedCategory.id &&
							coursesList.map((course) => {
								let selected = (selectedCoursesIds.includes(course.id)) ? 'selected' : '';
								let disabled = (disabledCourses.includes(course.id)) ? 'disabled' : ''
								return (
									<div
										className={`course ${selected} ${disabled}`}
										key={course.id}
										onClick={() => { if (disabled === '') selectCourse(course) }}
									>
										<div className="">
											{course.name.substring(2)}
										</div>
										<div className="course-price">
											<div className="time">
												<TimeIcon
													color="white"
													width={20}
												/>{' '}
												<p>{course.SimtalentCourseImpacts.time}</p>
											</div>

											<div className="currency">
												<MoneyIcon
													color="white"
													bgColor="transparent"
													width={20}
												/>{' '}
												<p>{course.SimtalentCourseImpacts.money}</p>
											</div>

										</div>
										<hr />
									</div>
								);
							})
						)}
					</div>
				</div>
			</div>
			{
				(selectedCoursesIds.length > 0 && props.session.eventCardAppearanceStatus.allowDevelopmentSelection) ?
					<ConfirmPurchase />
					:
					null
			}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		whiteCharacterStatus: state.session.whiteCharacterStatus,
		players: state.startingKit.players,
		session: state.session
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DevView);
